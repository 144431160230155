<template>
  <b-tr>
    <b-td class="white-space-nowrap">
      <div class="mb-1">
        <badge
          :status="userAnalysis.status"
          :statuses="statuses"
        />
      </div>
      {{userAnalysis.requestName}}
    </b-td>
    <b-td class="white-space-nowrap">
      <div>
        <strong>Richiesta:</strong>
        <br>
        {{userCreatedAt}}
      </div>
      <div class="mt-1">
        <strong>Ultima modifica:</strong>
        <br>
        {{userLastModified}}
      </div>
    </b-td>
    <b-td class="white-space-nowrap">
      <div v-if="referentToPerson">
        {{referentName}}
        <br>
        <strong>C.F.:</strong> {{userAnalysis.referentInfo.taxCode}}<br>
      </div>
      <div v-if="!referentToPerson">
        {{userAnalysis.referentInfo.companyName}}<br>
        <strong>P.IVA:</strong> {{userAnalysis.referentInfo.vatNumber}}<br>
      </div>
    </b-td>
    <b-td>
      <div>
        <strong>{{userAnalysis.labelRequestType}}</strong>
        <br>
        {{userAnalysis.labelRequestProduct}}
      </div>
    </b-td>
    <b-td class="white-space-nowrap table-cell--compact text-right vertical-align-middle">
      <b-button
        variant="success"
        size="sm"
        class="me-2 invisible"
        v-if="!hasPaymentOption"
      >
        <font-awesome-icon
          icon="credit-card"
          class="fa-fw"
        />
      </b-button>
      <b-button
        variant="success"
        @click="buyAction(userAnalysis.requestName)"
        size="sm"
        v-b-tooltip.hover.left
        title="Effettua pagamento"
        class="me-2"
        v-if="hasPaymentOption"
      >
        <font-awesome-icon
          icon="credit-card"
          class="fa-fw"
        />
      </b-button>
      <b-button
        class="btn-info ms-1"
        @click="downloadFile"
        v-if="!hideDownload && hasDownload"
        v-b-tooltip.hover
        title="Download"
      >
        <font-awesome-icon icon="cloud-download-alt" class="fa-fw"></font-awesome-icon>
      </b-button>
      <b-button
        variant="warning"
        @click="showDetail"
        size="sm"
        v-if="!hideDetail"
        v-b-tooltip.hover.left
        title="Dettaglio"
      >
        <font-awesome-icon
          icon="info-circle"
          class="fa-fw"
        />
      </b-button>
    </b-td>
  </b-tr>
</template>

<script>
import { formatDateTime, normalizeDisplayName } from '@/utils/formatterHelper';
import { isPresent } from '@/utils/validators';
import { USER_ANALYSIS_CLASS } from '@/utils/interfaces';
import { getEntityApiBase, openPrivateDocument } from '@/utils/documents';

const Badge = () => import('@/components/helpers/Badge.vue');

export default {
  name: 'UserAnalysisHistoryRow',
  components: {
    Badge,
  },
  props: {
    userAnalysis: { ...USER_ANALYSIS_CLASS },
    statuses: String,
    buyAction: Function,
    hideDownload: Boolean,
    hideDetail: Boolean,
  },
  data() {
    return {
      entityClass: 'USERANALYSIS',
    };
  },
  computed: {
    entityType() {
      if (this.isLoadedData) {
        if (this.$store.getters['subject/currentPerson'].personId) {
          return 'person';
        }
        return 'company';
      }
      return '';
    },
    entityId() {
      if (this.isLoadedData) {
        if (this.$store.getters['subject/currentPerson'].personId) {
          return this.$store.getters['subject/currentPerson'].personId;
        }
        return this.$store.getters['subject/currentCompany'].companyId;
      }
      return '';
    },
    userCreatedAt() {
      return formatDateTime(this.userAnalysis.createdAt);
    },
    userLastModified() {
      return formatDateTime(this.userAnalysis.lastModified);
    },
    referentToPerson() {
      return isPresent(this.userAnalysis.referentInfo.personId);
    },
    hasPaymentOption() {
      return this.userAnalysis?.status === 'WAIT_PAYMENT';
    },
    referentName() {
      if (this.referentToPerson) {
        const a = normalizeDisplayName(this.userAnalysis.referentInfo.name);
        const b = normalizeDisplayName(this.userAnalysis.referentInfo.surname);
        return `${a} ${b}`;
      }
      return '';
    },
    hasDownload() {
      return this.userAnalysis?.status === 'COMPLETED';
    },
  },
  methods: {
    showDetail() {
      this.$router.push(`/user-analysis-detail/${this.userAnalysis.requestName}`);
    },
    downloadFile() {
      this.$store.dispatch('documents/loadDocuments', {
        entityClass: this.entityClass,
        entityId: this.userAnalysis.requestName,
      }).then((response) => {
        console.log('downloadFile', response);
        if (response && response.length > 0) {
          const newestDocument = response.reduce(
            (latest, doc) => {
              const check = new Date(doc.lastModified) > new Date(latest.lastModified) ? doc : latest;
              return check;
            }, response[0],
          );
          openPrivateDocument(newestDocument.documentId, getEntityApiBase(this.entityClass));
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
